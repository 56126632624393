import React, { Component } from "react";
import { ThemeProvider } from "styled-components";
import { VerificationView } from "./views/VerificationView";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/ar";
import "@formatjs/intl-relativetimeformat/locale-data/es";
import messagesEn from "@educhain/utils/lang/en.json";
import messagesAr from "@educhain/utils/lang/ar.json";
import messagesEs from "@educhain/utils/lang/es.json";
import { IntlProvider } from "react-intl";
import { getTheme } from "@educhain/themes/src/";
import {
    AR_LOCALE,
    EN_LOCALE,
    ES_LOCALE,
    INTL_LTR,
    INTL_RTL
} from "@educhain/utils/index.js";
import { ConfigProvider } from "antd";
import { Redirect } from "react-router-dom";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { getBrowserLanguage } from "@educhain/utils/lang/getBrowserLanguage";
import GlobalStyle from '@educhain/ui/src/GlobalStyle';

const messages = {
    ar: messagesAr,
    en: messagesEn,
    es: messagesEs,
};

const theme = getTheme();

class Main extends Component {
    render() {
        const {
            match: {
                params: { locale }
            }
        } = this.props;

        const availableLanguages = [AR_LOCALE, EN_LOCALE, ES_LOCALE];
        const language = getBrowserLanguage(availableLanguages);

        // language defaults to english
        const languageWithoutRegionCode = !language
            ? EN_LOCALE
            : language.toLowerCase().split(/[_-]+/)[0];

        if (!locale || !availableLanguages.includes(locale)) {
            const location = window.location.pathname || "";
            return <Redirect to={`/${languageWithoutRegionCode}${location}`} />;
        }

        const direction =
            locale.toLowerCase() === AR_LOCALE ? INTL_RTL : INTL_LTR;
        return (
            <ConfigProvider direction={direction}>
                <ThemeProvider
                    theme={{
                        ...theme,
                        rtl: locale.toLowerCase() === AR_LOCALE
                    }}
                >
                    <>
                        <GlobalStyle />
                        <IntlProvider
                            locale={locale.toLowerCase()}
                            messages={messages[locale.toLowerCase()]}
                        >
                            <VerificationView />
                        </IntlProvider>
                    </>
                </ThemeProvider>
            </ConfigProvider>
        );
    }
}

export default withRouter(Main);
