import { Button } from "antd";
import { ButtonWrapper } from "./styled";
import React from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const BaseFileChooser = ({ intl, single }) => {
    return (
        <Wrapper>
            <ButtonWrapper>
                <Button type="primary">
                    {intl.formatMessage({ id: single ? "vp.chooseFileSingle" : "vp.chooseFile" })}
                </Button>
            </ButtonWrapper>
            <p key={"text"} className="ant-upload-text" style={{ marginBottom: '25px' }}>
                {intl.formatMessage({ id: single ? "vp.orDragSingle" : "vp.orDrag" })}
            </p>
        </Wrapper>
    );
};

export const FileChooser = injectIntl(BaseFileChooser);
