import React from "react";
import styled, { withTheme, css } from "styled-components";
import FileTextOutlined from "@ant-design/icons/lib/icons/FileTextOutlined";
import {
    ButtonWrapper,
    FileList,
    FileName,
    FileWrapper,
    StyledFileIcon
} from "./styled";
import { Button, Upload as AntUpload } from "antd";
import { FileChooser } from "./FileChooser";
import { injectIntl } from "react-intl";

const Dragger = AntUpload.Dragger;

const StyledDragger = styled.span`
    flex: 1 0 auto;
    width: 100%;

    .ant-upload.ant-upload-drag .ant-upload {
        padding: 0 0;
    }
    .ant-upload.ant-upload-drag {
        border-radius: 25px;
        border: 2px dashed #1b2845;

        ${props =>
            props.solid &&
            css`
                border: 2px solid #1b2845;
            `}
    }

    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: #1b2845;
        filter: brightness(1.1);
    }

    .ant-upload.ant-upload-drag p.ant-upload-text {
        color: ${({ theme: { colors } }) => colors.text};
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const BeforeValidatingCredentials = ({
    fileList,
    onFileUpload,
    onClose,
    onVerify,
    intl
}) => {
    return (
        <StyledDragger>
            <Dragger
                beforeUpload={() => false}
                onChange={onFileUpload}
                accept=".pdf,.png"
                openFileDialogOnClick={!fileList.length}
                showUploadList={false}
                onRemove={onClose}
                multiple={true}
                fileList={fileList}
            >
                <Wrapper>
                    {!fileList.length ? (
                        <FileChooser />
                    ) : (
                        <>
                            <FileList>
                                {fileList.map(file => (
                                    <FileWrapper key={file.uid}>
                                        <StyledFileIcon>
                                            <FileTextOutlined />
                                        </StyledFileIcon>
                                        <FileName
                                            name={file.name}
                                            onRemove={() => onClose(file.name)}
                                        />
                                    </FileWrapper>
                                ))}
                            </FileList>
                            <ButtonWrapper>
                                <Button type="primary" onClick={onVerify}>
                                    {intl.formatMessage({ id: "vp.verify" })}
                                </Button>
                            </ButtonWrapper>
                        </>
                    )}
                </Wrapper>
            </Dragger>
        </StyledDragger>
    );
};

export default injectIntl(withTheme(BeforeValidatingCredentials));
