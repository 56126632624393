import React from "react";
import { Dropdown, Menu } from "antd";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { FormattedMessage } from "react-intl";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import { Link } from "react-router-dom";

const BaseLanguageSelectorMenu = ({
    match: {
        params: { locale }
    },
    router,
    location
}) => {
    let pathname = null;

    if (router) {
        pathname = router.asPath;
    } else if (location.pathname) {
        pathname = location.pathname.slice(3);
    }

    const locales = [
        {
            locale: "ar",
            id: "shared.arabic"
        },
        {
            locale: "en",
            id: "shared.english"
        },
        {
            locale: "es",
            id: "shared.spanish"
        }
    ];

    const selectedLocale = locales.find(
        item => item.locale !== (locale || "en")
    );

    const menuItems = locales.map(item => (
        <Menu.Item key={item.id}>
            <Link to={`/${item.locale}${pathname}`}>
                <FormattedMessage id={item.id} />
            </Link>
        </Menu.Item>
    ));

    return (
        <Dropdown overlay={<Menu>{menuItems}</Menu>}>
            <Link
                className="ant-dropdown-link"
                to={`/${selectedLocale.locale}${pathname}`}
            >
                <FormattedMessage id={selectedLocale.id} /> <DownOutlined />
            </Link>
        </Dropdown>
    );
};

export const LanguageSelectorMenu = withRouter(BaseLanguageSelectorMenu);
