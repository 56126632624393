import React from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useRouter } from 'next/router';

export const withRouter = (Component) => {
    return (props) => {
        const history = typeof window !== 'undefined' ? useHistory() : {};
        const match = typeof window !== 'undefined' ? useRouteMatch() : {};
        const location = typeof window !== 'undefined' ? useLocation() : {};
        const router = useRouter();

        if (!match.params) {
            match.params = {};
        }

        if (router) {
            match.params.locale = router.locale;
        }

        return (
            <Component
                navigate={history.push}
                match={match}
                location={location}
                router={router}
                {...props}
            />
        );
    };
};
