// helper to create axios instance
import Axios from 'axios';
import { loadProgressBar } from "axios-progress-bar";
import { getCurrentUserToken } from "../../../passport/src/services/storage";

export function createAxios(config, tokenKey) {
    const axios = Axios.create(config);
    loadProgressBar({}, axios);
    axios.interceptors.request.use(config => {
        return tokenKey
            ? {
                  ...config,
                  headers: {
                      Authorization: 'Bearer ' + getCurrentUserToken(tokenKey)
                  }
              }
            : {
                  ...config
              };
    });
    return axios;
}
