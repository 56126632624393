import { defaultTheme } from '../default/theme';

const educhain = {
    ...defaultTheme,
    name: "Blue theme (default)",
    value: "educhain",
    brand: "EduChain",
    promotions: [
        {
            text: 'XYZ University IS IN THE TOP 10 University IN REGION',
            textStyle: { color: 'white' },
            image: 'promotion.jpg',
            buttonUrl: 'https://educhain.io',
            buttonText: 'Apply',
            buttonStyle: { color: 'white' },
        }
    ],
};

export { educhain };
