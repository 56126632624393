import { defaultTheme } from '../default/theme';

const footerLinks = [
    {
        text: "iuFooter.directory",
        href: "https://directory.iu.edu.sa/"
    },
    {
        text: "iuFooter.virtualTour",
        href: "https://iu.edu.sa/iuvr/islamic-univ.html"
    }
];

const iu = {
    ...defaultTheme,
    name: "Blue theme",
    value: "iu",
    brand: "IU",
    colors: {
        ...defaultTheme.colors,
        border: "#e5e5e5",
        primary: "#326E9C",
        primaryLight: "#B69D65",
        error: "#f5222d"
    },
    footerLinks: footerLinks,
};

export { iu };
