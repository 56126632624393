import { createAxios } from "@educhain/utils/src/services";

const TIMEOUT = 1500;

export const fakeCall = (timeout, data) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(data);
        }, TIMEOUT);
    });
};

const baseURL = process.env.REACT_APP_API_URL;

const axios = createAxios({
    headers: {
        TOKEN: "SECRET_TOKEN_FOR_API",
        "content-type": "multipart/form-data"
    },
    baseURL
});

export const exportFiles = (hash, locale) => {
    return axios.get(`v1/${locale}/export/${hash}`);
};

export const verify = (fileList, locale) => {
    const formData = new FormData();
    fileList.forEach(item => formData.append("files[]", item.originFileObj));
    return axios
        .post(`v1/${locale}/verify`, formData)
        .then(response => {
            return fakeCall(TIMEOUT, response);
        })
        .catch(error => {
            console.log(error.response);
        });
};

export const openbadgeVerify = (file, identifierType, identifier) => {
    const formData = new FormData();

    formData.append("file", file);

    if (identifierType) {
        formData.append('identifierType', identifierType);
    }

    if (identifier) {
        formData.append('identifier', identifier);
    }

    return axios
        .post(`v1/openbadge/validate`, formData)
        .then(response => response.data);
};
