import { defaultTheme } from '../default/theme';

const footerLinks = [
    {
        text: "kuFooter.contact",
        href: "https://www.ku.ac.ae/contact/"
    },
    {
        text: "kuFooter.disclaimer",
        href: "https://www.ku.ac.ae/disclaimer/"
    },
    {
        text: "kuFooter.terms",
        href: "https://www.ku.ac.ae/terms-and-conditions/"
    }
];

const ku = {
    ...defaultTheme,
    name: "Blue theme",
    value: "ku",
    brand: "KU",
    colors: {
        ...defaultTheme.colors,
        border: "#e5e5e5",
        primary: "#21397A",
        primaryLight: "#B6974A",
        error: "#f5222d"
    },
    footerLinks: footerLinks,
};

export { ku };
