import React, { Component } from "react";
import { throttle } from "lodash";

export const isSmall = width => width < 576;
export const isMedium = width => width < 768;
export const isLarge = width => width < 992;
export const isXLarge = width => width < 1200;

export const withSize = Wrapped =>
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                width: null,
                height: null,
                sm: false,
                md: false,
                lg: false,
                xl: false
            };

            this.handleResize = this.handleResize.bind(this);
        }

        handleResize() {
            this.setState({
                width: window.innerWidth,
                height: window.innerHeight,
                sm: isSmall(window.innerWidth),
                md: isMedium(window.innerWidth),
                lg: isLarge(window.innerWidth),
                xl: isXLarge(window.innerWidth)
            });
        }

        componentDidMount() {
            this.handleResize();
            window.addEventListener("resize", throttle(this.handleResize, 100));
        }

        componentWillUnmount() {
            window.removeEventListener(
                "resize",
                throttle(this.handleResize, 100)
            );
        }

        render() {
            return <Wrapped {...this.state} {...this.props} />;
        }
    };
