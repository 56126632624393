import React from "react";
import styled from "styled-components";
import { Alert, Image, Row, Col, Typography, Divider, Collapse } from 'antd';
import { getGraphByType, getGraphById, getReportMessageByName, isValidUrl } from '../../helpers/openbadge';
import ValidatingMessages from './ValidatingMessages';

const Wrapper = styled.div`
    width: 100%;
`;

const { Text } = Typography;
const { Panel } = Collapse;

const grid8 = {
    xl: { span: 6 },
    lg: { span: 10 },
    md: { span: 10 },
    flex: 1
};

const grid16 = {
    xl: { span: 18 },
    lg: { span: 14 },
    md: { span: 14 },
    flex: 1
};

const isBlockchainBlockVisible = (blockchain) => {
    if (!blockchain.message ||
        !blockchain.message.levels ||
        !blockchain.message.levels.FILE_UPLOADING ||
        !blockchain.message.levels.CONNECTION_CHECKING ||
        !blockchain.message.levels.HASH_VALIDATION
    ) {
        return false;
    }

    return !!blockchain.message.levels.FILE_UPLOADING.status &&
        !!blockchain.message.levels.CONNECTION_CHECKING.status &&
        !!blockchain.message.levels.HASH_VALIDATION.status;
}

const isBlockchainBlockValid = (blockchain) => {
    return !isBlockchainBlockVisible(blockchain) || blockchain.message.status;
}

export const OpenbadgeValidationResult = ({ intl, validationResult, theme }) => {
    const { openbadge, blockchain } = validationResult;
    const { graph, report } = openbadge;
    const validationSubject = getGraphById(graph || [], report?.validationSubject);
    const badge = getGraphByType(graph || [], 'BadgeClass');
    const assertion = getGraphByType(graph || [], 'Assertion');
    const issuer = getGraphByType(graph || [], 'Issuer') || getGraphByType(graph || [], 'Profile');
    const isBlockchainValid = isBlockchainBlockValid(blockchain);
    const isValid = report.valid;
    const status = isValid ? intl.formatMessage({ id: 'vp.openbadge.statusValid'}) : intl.formatMessage({ id: 'vp.openbadge.statusInvalid'});
    const recipientError = getReportMessageByName(report, 'VERIFY_RECIPIENT_IDENTIFIER');

    return (
        <Wrapper>
            {
                isBlockchainBlockVisible(blockchain) && !isBlockchainValid &&
                <Alert
                    style={{ marginBottom: '20px' }}
                    type={isBlockchainValid ? 'success' : 'error'}
                    message={intl.formatMessage({ id: 'vp.openbadge.validMessage'}, { valid: isBlockchainValid ? 'true' : 'false' })}
                    description={isBlockchainValid
                        ? intl.formatMessage({ id: 'vp.blockchain.passedMessage'})
                        : intl.formatMessage({ id: 'vp.blockchain.failedMessage'})}
                    showIcon
                />
            }
            <Alert
                type={isValid ? 'success' : 'error'}
                message={intl.formatMessage({ id: 'vp.openbadge.validMessage'}, { valid: isValid ? 'true' : 'false' })}
                description={isValid
                    ? intl.formatMessage({ id: 'vp.openbadge.passedMessage'})
                    : intl.formatMessage({ id: 'vp.openbadge.errorCountMessage'}, { count: report.errorCount })}
                showIcon
            />
            <Row>
                {
                    assertion &&
                    <Col {...grid8}>
                        <Image width={200} src={assertion.image} preview={false}/>
                    </Col>
                }
                <Col {...grid16}>
                    {
                        isBlockchainBlockVisible(blockchain) &&
                        <>
                            <Divider orientation="left" orientationMargin={0}>
                                {intl.formatMessage({ id: 'vp.openbadge.blockchain'})}
                            </Divider>
                            <ValidatingMessages item={blockchain} theme={theme} />
                        </>
                    }
                    {
                        badge &&
                        <>
                            <Divider orientation="left" orientationMargin={0}>
                                {intl.formatMessage({ id: 'vp.openbadge.aboutBadge'})}
                            </Divider>
                            <h3>{badge.name}</h3>
                            <p><Text italic>{badge.description}</Text></p>
                            {
                                isValidUrl(badge.id) &&
                                <p>
                                    <a href={badge.id} target="_blank">
                                        {intl.formatMessage({ id: 'vp.openbadge.viewBadgeDetails'})}
                                    </a>
                                </p>
                            }
                        </>
                    }
                    {
                        assertion &&
                        <>
                            <Divider orientation="left" orientationMargin={0}>
                                {intl.formatMessage({ id: 'vp.openbadge.aboutAward'})}
                            </Divider>
                            <p><b>{intl.formatMessage({ id: 'vp.openbadge.issueDate'})}</b> {assertion.issuedOn}</p>
                            <p><b>{intl.formatMessage({ id: 'vp.openbadge.expiration'})}</b> {assertion.expires ? assertion.expires : intl.formatMessage({ id: 'vp.openbadge.notExpire'})}</p>
                        </>
                    }
                    {
                        assertion && !recipientError && !report?.recipientProfile &&
                        <Alert
                            style={{ marginBottom: '1em' }}
                            type={'warning'}
                            message={<><b>{intl.formatMessage({ id: 'vp.openbadge.recipientNotVerified'})}</b> {intl.formatMessage({ id: 'vp.openbadge.recipientNotVerifiedMessage'})}</>}
                        />
                    }
                    {
                        report?.recipientProfile &&
                        Object.keys(report.recipientProfile).map(type => {
                            const value = report.recipientProfile[type];

                            return (
                                <p key={`recipient${type}`}><b>{intl.formatMessage({ id: 'vp.openbadge.recipientVerificationSuccess'}, { type })}</b> {value}</p>
                            );
                        })
                    }
                    {
                        recipientError &&
                        <Alert
                            style={{ marginBottom: '1em' }}
                            type={'error'}
                            message={<><b>{intl.formatMessage({ id: 'vp.openbadge.recipientVerificationFailed'})}</b> {recipientError.result}</>}
                        />
                    }
                    {
                        assertion && isValidUrl(assertion.id) &&
                        <p>
                            <a href={assertion.id} target="_blank">
                                {intl.formatMessage({ id: 'vp.openbadge.viewAwardDetails'})}
                            </a>
                        </p>
                    }
                    {
                        issuer &&
                        <>
                            <Divider orientation="left" orientationMargin={0}>
                                {intl.formatMessage({ id: 'vp.openbadge.aboutIssuer'})}
                            </Divider>
                            {issuer.name && <h3>{issuer.name}</h3>}
                            <Row>
                                {
                                    isValidUrl(issuer.url) &&
                                    <Col>
                                        <a href={issuer.url} target="_blank">
                                            {intl.formatMessage({ id: 'vp.openbadge.viewIssuerWebsite'})}
                                        </a>
                                    </Col>
                                }
                                {
                                    issuer.email &&
                                    <Col>
                                        <a href={`mailto:${issuer.email}`} target="_blank">
                                            {intl.formatMessage({ id: 'vp.openbadge.viewIssuerEmail'})}
                                        </a>
                                    </Col>
                                }
                                {
                                    isValidUrl(issuer.id) &&
                                    <Col>
                                        <a href={issuer.id} target="_blank">
                                            {intl.formatMessage({ id: 'vp.openbadge.viewIssuerDetails' })}
                                        </a>
                                    </Col>
                                }
                            </Row>
                        </>
                    }
                </Col>
            </Row>
            <Divider orientation="left" orientationMargin={0}>
                {intl.formatMessage({ id: 'vp.openbadge.verificationDetails'})}
            </Divider>
            <p><b>{intl.formatMessage({ id: 'vp.openbadge.status'})}</b> {intl.formatMessage({ id: 'vp.openbadge.statusMessage'}, {
                status,
                errorsCount: report.errorCount,
                warningCount: report.warningCount
            })}</p>
            <p><b>{intl.formatMessage({ id: 'vp.openbadge.dataType'})}</b> {validationSubject ? validationSubject.type : null}</p>
            <p><b>{intl.formatMessage({ id: 'vp.openbadge.openbadgeVersion'})}</b> {report?.openBadgesVersion}</p>
            {
                report?.messages && report?.messages.length > 0 &&
                <>
                    <p><b>{intl.formatMessage({ id: 'vp.openbadge.errorsAndInfo'})}</b></p>
                    {report.messages.map(({ messageLevel, name, result }) => {
                        return (
                            <Alert
                                key={`message${name}${messageLevel}`}
                                style={{ marginBottom: '1em' }}
                                type={messageLevel.toLowerCase()}
                                message={<><b>{name}:</b> {result}</>}
                            />
                        );
                    })}
                </>
            }
            <Collapse style={{ marginBottom: '1em' }}>
                <Panel header={intl.formatMessage({ id: 'vp.openbadge.showFullData'})} key="1">
                    <pre>{JSON.stringify(validationResult, undefined, 2)}</pre>
                </Panel>
            </Collapse>
        </Wrapper>
    );
};
