import { defaultTheme } from '../default/theme';

const footerLinks = [
    {
        text: "shared.support",
        href: "mailto:passport@reptondubai.org"
    },
    {
        text: "footer.privacy",
        href: "https://www.reptondubai.org/privacy-policy/"
    },
    {
        text: "shared.educhain",
        href: "https://educhain.io"
    }
];

const repton_dxb = {
    ...defaultTheme,
    name: "Repton Dubai",
    value: "repton_dxb",
    brand: "Repton",
    logo: {
        width: '70px',
    },
    colors: {
        ...defaultTheme.colors,
        border: "#e5e5e5",
        primary: "#243253",
        primaryLight: "#243253",
        primaryLighter: "#A09F00",
        primaryDark: "#243253",
        btnPrimary: "#A09F00",
        h3: "#A09F00",
        tabBackground: "#243253",
        tab: "#ffffff",
        tabActive: "#ffffff",
        text: "#243253",
        error: "#f5222d"
    },
    footerLinks: footerLinks,
    media: true,
    dashboard: {
        title: 'menu.dashboard',
        url: '/',
        categories: [
            {
                url: '/credentials/pre-academic-21-22',
                name: 'pre-academic-21-22',
                title: null,
                image: 'pre-academic-21-22.png',
                categories: [],
            },
            {
                url: '/category/repton-passport',
                name: 'repton-passport',
                title: null,
                image: 'passport-21-22.jpg',
                categories: [
                    {
                        url: '/credentials/credentials-21-22',
                        name: 'credentials-21-22',
                        title: null,
                        image: 'credentials-21-22.png',
                        categories: [],
                    },
                    {
                        url: '/media-files/media-21-22',
                        name: 'media-21-22',
                        title: null,
                        image: 'media-21-22.png',
                        categories: [],
                    },
                ],
            }
        ],
    },
    assets: {
        ...defaultTheme.assets,
        headingFont: 'BemboStd',
        textFont: 'OpenSans',
        tabBarWidth: '5px',
    },
    fonts: [
        {
            name: 'GillSans',
            url: '/assets/GillSans.otf',
            format: 'opentype',
            weight: 'normal',
            style: 'normal',
        },
        {
            name: 'GillSans',
            url: '/assets/GillSansBold.otf',
            format: 'opentype',
            weight: 'bold',
            style: 'normal',
        },
        {
            name: 'BemboStd',
            url: '/assets/BemboStdBold.otf',
            format: 'opentype',
            weight: 'bold',
            style: 'normal',
        },
        {
            name: 'OpenSans',
            url: '/assets/OpenSans.ttf',
            format: 'truetype',
            weight: 'normal',
            style: 'normal',
        },
        {
            name: 'OpenSans',
            url: '/assets/OpenSansBold.ttf',
            format: 'truetype',
            weight: 'bold',
            style: 'normal',
        },
        {
            name: 'OpenSans',
            url: '/assets/OpenSansItalic.ttf',
            format: 'truetype',
            weight: 'normal',
            style: 'italic',
        },
        {
            name: 'OpenSans',
            url: '/assets/OpenSansBoldItalic.ttf',
            format: 'truetype',
            weight: 'bold',
            style: 'italic',
        },
    ],
    passport: {
        pageSubtitle: 'Digital Year Book',
        faq: {
            style: {},
            html: ``,
        },
    },
    verify: {
        showHeaderTitle: false,
        pageSubtitle: 'Verification Portal',
        title: 'Repton Passport – Verification Portal',
        head: {
            align: 'right',
        },
        started: {
            align: 'center',
            text: 'Welcome to the verification portal for Repton Passport. Please use this portal to verify credentials issued by the school.',
        },
        faq: {
            style: {
                fontFamily: 'OpenSans',
                color: '#243253',
            },
            html: `<h3>What is the Verification Portal?</h3>
                <p>Repton’s Verification Portal is a public page for the verification of digital documents such as school reports, certificates, and letters issued by the School. The Verification Portal serves as a point where blockchain-enabled digital documents can be validated.</p>
                <p>&nbsp;</p>
                <h3>How does it work?</h3>
                <p>After receipt of digital credentials issued by the School, please select and submit the file(s) and the portal will automatically process the request, validating the credential(s) in a few seconds.&nbsp;&nbsp;</p>
                <p>&nbsp;</p>
                <h3>Verification Result</h3>
                <p>If verification is performed successfully, you will see a successful result authenticating the submitted credential(s):</p>
                <p>&nbsp;</p>
                <p>
                    <em>
                        <strong>“Success. This is an original document digitally issued by Repton School.”</strong>
                    </em>
                </p>
                <p>&nbsp;</p>
                <p>If not successful, you will receive a failed verification message, with further clarification on which step failed the verification.</p>`,
        },
    },
};

export { repton_dxb };
