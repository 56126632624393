import React from "react";
import styled from "styled-components";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    MinusCircleOutlined
} from "@ant-design/icons";

const TooltipIcon = styled.span`
    .anticon {
        color: ${({ color }) => color};
        ${({ theme: { rtl } }) =>
            rtl ? "margin-left: 10px; " : "margin-right: 10px;"}
    }
`;

const PopoverWrapper = styled.div`
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -o-flex;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;

const ValidatingMessages = ({ item, theme }) => {
    const getIconForStatus = status => {
        if (status === 1) {
            return (
                <TooltipIcon color={theme.colors._52c41a}>
                    <CheckCircleOutlined />
                </TooltipIcon>
            );
        } else if (status === 0) {
            return (
                <TooltipIcon color={theme.colors.gray}>
                    <MinusCircleOutlined />
                </TooltipIcon>
            );
        } else if (status === -1) {
            return (
                <TooltipIcon color={theme.colors.danger}>
                    <CloseCircleOutlined />
                </TooltipIcon>
            );
        }
    };

    const convertVerifyObjectToIconMessageArray = verificationMessage => {
        let hasFailed = false;
        let res = [];
        for (const [key, value] of Object.entries(verificationMessage.levels || [])) {
            if (hasFailed === true) {
                res.push([getIconForStatus(0), value.message]);
            } else if (value.status === false) {
                hasFailed = true;
                res.push([getIconForStatus(-1), value.message]);
            } else {
                res.push([getIconForStatus(1), value.message]);
            }
        }
        return res;
    };

    const mapIconMessageArrayToList = results => {
        return (
            <PopoverWrapper>
                <ul>
                    {results.map(arr => {
                        return (
                            <li key={arr[1]}>
                                {arr[0]}
                                {arr[1]}
                            </li>
                        );
                    })}
                </ul>
            </PopoverWrapper>
        );
    };

    const iconMessageArray = convertVerifyObjectToIconMessageArray(
        item.message
    );

    return mapIconMessageArrayToList(iconMessageArray);
};

export default ValidatingMessages;
