import { defaultTheme } from '../default/theme';

const footerLinks = [
    {
        text: "ubtFooter.eServices",
        href: "https://www.ubt.edu.sa/Eservice_2"
    },
    {
        text: "ubtFooter.contact",
        href: "https://www.ubt.edu.sa/Contact-Us"
    },
    {
        text: "ubtFooter.apply",
        href: "https://www.ubt.edu.sa/Apply-now"
    },
    {
        text: "ubtFooter.directory",
        href: "https://www.ubt.edu.sa/Directory"
    },
    {
        text: "ubtFooter.siteMap",
        href: "https://www.ubt.edu.sa/Sitegraph"
    }
];

const ubt = {
    ...defaultTheme,
    name: "Dark Blue theme",
    value: "ubt",
    brand: "UBT",
    colors: {
        ...defaultTheme.colors,
        border: "#e5e5e5",
        primary: "#001F5F",
        primaryLight: "#001F5F",
        error: "#f5222d"
    },
    footerLinks: footerLinks,
};

export { ubt };
