import { defaultTheme } from '../default/theme';

const footerLinks = [
    {
        text: "shared.support",
        href: "#"
    },

    {
        text: "footer.terms",
        href: "#"
    },
    {
        text: "footer.privacy",
        href: "#"
    },
    {
        text: "shared.educhain",
        href: "https://educhain.io"
    }
];

const mbru = {
    ...defaultTheme,
    name: "MBRU",
    value: "mbru",
    brand: "MBRU",
    colors: {
        ...defaultTheme.colors,
        primary: "#017DBD",
        primaryLight: "#017DBD",
        primaryDark: "#017DBD",
    },
    footerLinks: footerLinks,
};

export { mbru };
