import React from "react";
import styled, { withTheme, css } from "styled-components";
import FileTextOutlined from "@ant-design/icons/lib/icons/FileTextOutlined";
import {
    FileList,
    FileName,
    FileWrapper,
    StyledFileIcon
} from "./styled";
import { Col, Row, Button, Upload as AntUpload, Form, Select, Input, Divider } from "antd";
import { FileChooser } from "./FileChooser";
import { injectIntl } from "react-intl";

const { Dragger } = AntUpload;
const { Option } = Select;

const StyledDragger = styled.span`
    width: 100%;
    display: block;

    .ant-upload.ant-upload-drag .ant-upload {
        padding: 0 0;
    }
    .ant-upload.ant-upload-drag {
        border-radius: 25px;
        border: 2px dashed #1b2845;

        ${props =>
            props.solid &&
            css`
                border: 2px solid #1b2845;
            `}
    }

    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: #1b2845;
        filter: brightness(1.1);
    }

    .ant-upload.ant-upload-drag p.ant-upload-text {
        color: ${({ theme: { colors } }) => colors.text};
    }
`;

const Wrapper = styled.div`
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const FormWrapper = styled.div`
    .ant-divider-horizontal.ant-divider-with-text {
        color: ${({ theme: { colors } }) => colors.text};
    }
`;

const OpenbadgeForm = ({
    fileList,
    fileError,
    onFileUpload,
    onClose,
    onVerify,
    intl
}) => {
    const [form] = Form.useForm();
    const grid8 = {
        xl: { span: 8 },
        lg: { span: 8 },
        md: { span: 8 },
        sm: { span: 8 },
        xs: { span: 24 },
        flex: 1
    };

    const grid16 = {
        xl: { span: 16 },
        lg: { span: 16 },
        md: { span: 16 },
        sm: { span: 16 },
        xs: { span: 24 },
        flex: 1
    };

    const onFinish = (values) => {
        if (fileList.length > 0) {
            onVerify(values);
            form.resetFields();
        }
    };

    return (
        <FormWrapper>
            <Form form={form} onFinish={onFinish} initialValues={{ identifierType: 'email' }}>
                <StyledDragger>
                    <Dragger
                        beforeUpload={() => false}
                        onChange={onFileUpload}
                        openFileDialogOnClick={!fileList.length}
                        showUploadList={false}
                        onRemove={onClose}
                        fileList={fileList}
                    >
                        <Wrapper>
                            {!fileList.length ? (
                                <FileChooser single error={fileError} />
                            ) : (
                                <>
                                    <FileList>
                                        {fileList.map(file => (
                                            <FileWrapper key={file.uid}>
                                                <StyledFileIcon>
                                                    <FileTextOutlined />
                                                </StyledFileIcon>
                                                <FileName
                                                    name={file.name}
                                                    onRemove={() => onClose(file.name)}
                                                />
                                            </FileWrapper>
                                        ))}
                                    </FileList>
                                </>
                            )}
                        </Wrapper>
                    </Dragger>
                </StyledDragger>
                <Divider orientation="left" style={{ marginBottom: 0 }}>Verify Recipient</Divider>
                <Row justify={"center"}>
                    <Col {...grid8}>
                        <Form.Item name="identifierType" style={{ paddingRight: '15px' }}>
                            <Select>
                                <Option value="email">email</Option>
                                <Option value="url">url</Option>
                                <Option value="telephone">telephone</Option>
                                <Option value="JSON-LD ID">JSON-LD ID</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col {...grid16}>
                        <Form.Item name="identifier">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                {fileList.length > 0 &&
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit">
                        {intl.formatMessage({ id: "vp.verify" })}
                    </Button>
                </Form.Item>
                }
            </Form>
        </FormWrapper>
    );
};

export default injectIntl(withTheme(OpenbadgeForm));
