import { defaultTheme } from '../default/theme';

const footerLinks = [
    {
        text: "footer.disclaimer",
        href: 'https://www.zu.ac.ae/main/{locale}/support/disclaimer.aspx'
    },

    {
        text: "footer.terms",
        href: "https://www.zu.ac.ae/main/{locale}/support/terms_conditions.aspx"
    },
    {
        text: "footer.privacy",
        href: "https://www.zu.ac.ae/main/{locale}/support/privacy_policy.aspx"
    },
    {
        text: "footer.help",
        href: "https://www.zu.ac.ae/main/{locale}/support/help.aspx"
    }
];

const zu = {
    ...defaultTheme,
    name: "Dark Red Theme",
    value: "zu",
    brand: "ZU",
    colors: {
        ...defaultTheme.colors,
        border: "#e5e5e5",
        primary: "#910029",
        primaryLight: "#910029",
        error: "#f5222d"
    },
    footerLinks: footerLinks,
    phone: null,
    email: "registrar@zu.ac.ae",
};

export { zu };
