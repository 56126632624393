import React from "react";
import styled, { withTheme } from "styled-components";
import {
    FileTextOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    MinusCircleOutlined
} from "@ant-design/icons";
import { Button, Popover } from "antd";
import { FileList, FileName, FileWrapper } from "./styled";
import { injectIntl } from "react-intl";
import ValidatingMessages from './ValidatingMessages';

const TooltipIcon = styled.span`
    .anticon {
        color: ${({ color }) => color};
        ${({ theme: { rtl } }) =>
            rtl ? "margin-left: 10px; " : "margin-right: 10px;"}
    }
`;
const Wrapper = styled.div`
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;

    .ant-btn {
        margin-top: 30px;
    }
`;

const PopoverWrapper = styled.div`
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -o-flex;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;
const StyledFileIcon = styled.span`
    .anticon {
        font-size: 50px;
        color: #1b2845;
    }
`;
const ResultIcon = styled.div`
    .anticon {
        opacity: 1;
        font-size: 20px;
        //background-color: rgba(231, 0, 0, 0.6);
        border-radius: 50%;
        color: ${({ success, theme: { colors } }) =>
            success ? colors._52c41a : colors.danger};
        position: absolute;
        right: 35px;
        top: -15px;
        margin-top: 15px;
    }
`;

const ToolTipWrapper = styled.div`
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -o-flex;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    li {
        display: flex;
    }
`;

const StyledIcon = styled.span`
    .anticon {
        ${({ theme: { rtl } }) =>
            rtl ? "margin-left: 10px; " : "margin-right: 10px;"}

        color: ${({ color }) => color};
    }
`;

const BaseFinishedValidating = ({ data, fileList, intl, onExport, theme }) => {
    const files = data.status ? data.data.results : fileList;

    return (
        <Wrapper>
            <FileList>
                {files.map(item => {
                    const content = <ValidatingMessages item={item} theme={theme} />;

                    return (
                        <Popover
                            placement="top"
                            content={content}
                            trigger={data.status ? "hover" : ""}
                            key={item.name}
                        >
                            <FileWrapper>
                                <ResultIcon
                                    success={
                                        data.status &&
                                        item.status.toLowerCase() === "success"
                                    }
                                >
                                    {data.status &&
                                        (item.status.toLowerCase() ===
                                        "success" ? (
                                            <CheckCircleOutlined />
                                        ) : (
                                            <CloseCircleOutlined />
                                        ))}
                                </ResultIcon>
                                <StyledFileIcon>
                                    <FileTextOutlined />
                                </StyledFileIcon>
                                <FileName name={item.name} />
                            </FileWrapper>
                        </Popover>
                    );
                })}
            </FileList>
            <Button type="primary" onClick={onExport}>
                {intl.formatMessage({ id: "vp.export" })}
            </Button>
            <Button type="default" onClick={() => window.location.reload()}>
                {intl.formatMessage({ id: "vp.restart" })}
            </Button>
        </Wrapper>
    );
};

export const FinishedValidating = injectIntl(withTheme(BaseFinishedValidating));
