import React from "react";
import { LayoutWrapper } from "@educhain/ui/src/layout/LayoutWrapper";
import { LanguageSelectorMenu } from "@educhain/ui/src/footer/LanguageSelector";
import { injectIntl } from "react-intl";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { Footer } from "@educhain/ui/src/footer/Footer";
import { Header } from "@educhain/ui/src/header/Header";
import { isVerifyRTLEnabled } from "../helper";

const VerifyLayout = ({ children, intl }) => {
    return (
        <LayoutWrapper>
            <Header
                title={intl.formatMessage({ id: "myc.verifyCredential" })}
            />
            <div className="content">{children}</div>
            <Footer
                className="footer"
                redirectName={intl.formatMessage({
                    id: "menu.academicPassport"
                })}
                redirectPath={process.env.REACT_APP_AP_BASE_URL}
                isRTLEnabled={isVerifyRTLEnabled()}
                langSwitch={LanguageSelectorMenu}
            />
        </LayoutWrapper>
    );
};

export default injectIntl(withRouter(VerifyLayout));
