import { defaultTheme } from '../default/theme';

const aud = {
    ...defaultTheme,
    name: "Dark Blue Theme",
    value: "aud",
    brand: "AUD",
    colors: {
        ...defaultTheme.colors,
        border: "#e5e5e5",
        primary: "#245690",
        primaryLight: "#245690",
        error: "#f5222d"
    },
    footerLinks: [],
    phone: null,
    email: "registrar@zu.ac.ae",
};

export { aud };
