import styled from "styled-components";

export const LayoutWrapper = styled.div`
    width: 100%;
    min-height: 100%;
    background: white;
    height: 100%;
    display: flex;
    flex-direction: column;

    .content {
        flex: 1 0 auto;
        background: white;
        display: flex;
    }

    .footer {
        flex-shrink: 0;
        background-color: white;
        padding: 20px 50px;
    }
`;
