import defaultColors from "../defaultColors";
import defaultAssets from "../defaultAssets";

const footerLinks = [
    {
        text: "shared.support",
        href: "#"
    },

    {
        text: "footer.terms",
        href: "#"
    },
    {
        text: "footer.privacy",
        href: "#"
    },
    {
        text: "shared.educhain",
        href: "#"
    }
];

const defaultTheme = {
    name: "Default",
    value: "default",
    brand: "default",
    website: "#",
    colors: {
        ...defaultColors
    },
    footerLinks: footerLinks,
    verifyURL: "http://verify.example.com",
    phone: "+971 55 160 5623",
    email: "support@educhain.io",
    assets: {
        ...defaultAssets
    },
    promotions: [],
    // promotions: [
    //     {
    //         text: '',
    //         textStyle: { color: 'white' },
    //         image: '',
    //         buttonUrl: '',
    //         buttonText: '',
    //         buttonStyle: { color: 'white' },
    //     }
    // ],
    logo: null,
    // logo: {
    //     width: '125px',
    // },
    media: false,
    dashboard: null,
    // dashboard: {
    //     title: 'menu.dashboard',
    //     url: '/',
    //     categories: [
    //         {
    //             url: '',
    //             name: '',
    //             title: null,
    //             image: '',
    //             categories: [
    //                 {
    //                     url: '',
    //                     name: '',
    //                     title: null,
    //                     image: '',
    //                     categories: [],
    //                 },
    //             ],
    //         }
    //     ],
    // },
    menuTitle: null,
    phoneName: null,
    headerMessage: null,
    footerCC: null,
    ldap: false,
    fonts: [],
    // fonts: [
    //     {
    //         name: 'Arial',
    //         url: '/assets/Arial.otf',
    //         format: 'opentype',
    //         weight: 'bold',
    //         style: 'italic',
    //     }
    // ],
    passport: {
        showHeaderTitle: true,
        pageSubtitle: "Academic Passport",
        faq: {
            style: {},
            html: ``,
        },
    },
    verify: {
        showHeaderTitle: true,
        title: '',
        pageSubtitle: "Verification Portal",
        head: {
          align: 'left',
        },
        started: {
            align: 'left',
            text: '',
        },
        // faq: {
        //     style: {},
        //     html: ``,
        // },
    },
};

export { defaultTheme };
