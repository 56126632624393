import { defaultTheme } from '../default/theme';

const footerLinks = [
    {
        text: "adFooter.overview",
        href: "https://www.admacademy.ae/about-us/overview/"
    },
    {
        text: "adFooter.news",
        href: "https://www.admacademy.ae/media/news-events/"
    },
    {
        text: "adFooter.contact",
        href: "https://www.admacademy.ae/contact-us/"
    }
];

const ad = {
    ...defaultTheme,
    name: "Blue theme",
    value: "ad",
    brand: "ADMA",
    colors: {
        ...defaultTheme.colors,
        border: "#e5e5e5",
        primary: "#5472d2",
        primaryLight: "#5472d2",
        error: "#f5222d"
    },
    footerLinks: footerLinks,
};

export { ad };
