import { defaultTheme } from '../default/theme';

const footerLinks = [
    {
        text: "Feedback",
        href:
            "https://amsi.ae/en/component/content/article/60-feedback/97-feedback"
    },
    {
        text: "Privacy Policy",
        href: "https://amsi.ae/en/component/content/article/105"
    },
    {
        text: "Terms and Conditions",
        href: "https://amsi.ae/en/component/content/article/105"
    },
    {
        text: "©2021 AMSI",
        href: "https://amsi.ae/"
    }
];

const amsi = {
    ...defaultTheme,
    name: "Blue theme for AMSI",
    value: "amsi",
    brand: "AMSI",
    website: "https://amsi.ae",
    colors: {
        ...defaultTheme.colors,
        border: "#e5e5e5",
        primary: "#318ae9",
        primaryLight: "#6eadf0",
        primaryDark: "#1360b2",
        error: "#318ae9"
    },
    footerLinks: footerLinks,
    verifyURL: "https://verificationt.amsi.ae/",
};
export { amsi };
