import { defaultTheme } from '../default/theme';

const footerLinks = [
    {
        text: "shared.support",
        href: "https://www.sharjah.ac.ae/en/about/contact_us/Pages/default.aspx"
    },

    {
        text: "footer.terms",
        href: "https://www.sharjah.ac.ae/en/Pages/PrivacyP.aspx"
    },
    {
        text: "footer.privacy",
        href: "https://www.sharjah.ac.ae/en/Pages/PrivacyP.aspx"
    },
    {
        text: "shared.educhain",
        href: "https://educhain.io"
    }
];

const uos = {
    ...defaultTheme,
    name: "University of Sharjah",
    value: "uos",
    brand: "UOS",
    logo: {
        width: '125px',
    },
    colors: {
        ...defaultTheme.colors,
        primary: "#00C389",
        primaryLight: "#00C389",
        primaryDark: "#00C389",
    },
    footerLinks: footerLinks,
};

export { uos };
