export default {
    background: "#f5f5f5",
    border: "#e5e5e5",
    text: "#424242",
    primary: "#318ae9",
    secondary: "#1B2845",
    primaryLight: "#6eadf0",
    primaryLighter: "#424242",
    primaryLightest: "#ffebe8",
    primaryDark: "#1360b2",
    btnPrimary: '#318ae9',
    error: "#318ae9",
    danger: "rgb(237,42,50)",
    gray: "#d9d9d9",
    _393838: "#393838",
    _52c41a: "#52c41a",
    _faad14: "#faad14"
};
