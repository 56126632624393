import React from "react";
import { Layout } from "antd";
import styled, { withTheme } from "styled-components";
import { getImagePath } from "@educhain/utils/theme/getImagePath";

const { Header: AntHeader } = Layout;

const StyledHeader = styled(AntHeader)`
    display: flex;
    background: white;
    margin: 0 30px 0 30px;
    justify-content: space-between;
    padding: 10px 0;
    min-height: 75px;
    line-height: 75px;
    height: auto;

    .page-title {
        font-family: ${({ theme: { assets } }) => assets.headingFont};
        color: ${({ theme: { colors } }) => colors.primaryLight};
        font-weight: 600;
        text-transform: capitalize;
        font-size: 20px;
        @media (min-width: 600px) {
            padding: 0 15px;
            font-size: 24px;
        }
        @media (max-width: 450px) {
            display: none;
        }
    }

    .nav-item {
        padding: 0 15px;
    }
`;

const Wrapper = styled.div`
    background-color: white;
    box-shadow: 0 2px 8px #f0f1f2;
    z-index: 1;
`;

const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

const Logo = styled.img`
    height: ${({ theme: { logo } }) => logo && logo.width ? 'auto' : '48px' };
    width: ${({ theme: { logo } }) => logo && logo.width ? logo.width : 'auto' };
`;

const RightWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        margin: 0 5px;
    }
`;

const BaseHeader = ({ theme, title }) => {
    return (
        <Wrapper>
            <StyledHeader>
                <ImageWrapper>
                    <Logo alt="" src={getImagePath(theme.assets.logo)} />
                </ImageWrapper>
                <RightWrapper>
                    {title && theme.verify.showHeaderTitle && <div className="page-title">{title}</div>}
                </RightWrapper>
            </StyledHeader>
        </Wrapper>
    );
};

export const Header = withTheme(BaseHeader);
