import React from "react";
import { get } from "lodash";
import { withTheme } from "styled-components";
import { uaeu } from "./uaeu/theme";
import { educhain } from "./educhain/theme";
import { ubt } from "./ubt/theme";
import { iu } from "./iu/theme";
import { ku } from "./ku/theme";
import { ad } from "./ad/theme";
import { amsi } from "./amsi/theme";
import { zu } from "./zu/theme";
import { aud } from "./aud/theme";
import { repton_dxb } from "./repton_dxb/theme";
import { repton_barsha } from "./repton_barsha/theme";
import { repton_ad } from "./repton_ad/theme";
import { uos } from "./uos/theme";
import { mbru } from "./mbru/theme";

// TODO better error handling if this doesn't exist
const config = require("../theme-config.json");

// TODO create docs for making a new theme, it involves creating this constant here
export const THEME_UAEU = 'uaeu';
export const THEME_EDUCHAIN = 'educhain';
export const THEME_AMSI = 'amsi';
export const THEME_UBT = 'ubt';
export const THEME_IU = 'iu';
export const THEME_KU = 'ku';
export const THEME_AD = 'ad';
export const THEME_ZU = 'zu';
export const THEME_AUD = 'aud';
export const THEME_REPTON_DXB = 'repton_dxb';
export const THEME_REPTON_BARSHA = 'repton_barsha';
export const THEME_REPTON_AD = 'repton_ad';
export const THEME_UOS = 'uos';
export const THEME_MBRU = 'mbru';

export const themes = [
    educhain,
    uaeu,
    ubt,
    iu,
    ku,
    ad,
    amsi,
    zu,
    aud,
    repton_dxb,
    repton_barsha,
    repton_ad,
    uos,
    mbru,
];

export const getTheme = () => {
    return themes.find(theme => theme.value === config.theme) || educhain;
};

const BaseThemeComponentSwitcher = (options) => {
    const {
        theme: { value },
        components,
        defaultComponent,
        childProps
    } = options;
    const Component = get(components, value, defaultComponent);

    return <Component {...childProps} />;
};

export const ThemeComponentSwitcher = withTheme(BaseThemeComponentSwitcher);
