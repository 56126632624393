import React, { useState } from 'react';
import OpenbadgeForm from './OpenbadgeForm';
import { OpenbadgeValidating } from './OpenbadgeValidating';
import { OpenbadgeValidationResult } from './OpenbadgeValidationResult';
import { openbadgeVerify } from "../../api";
import { Alert, Button } from 'antd';

const OpenbadgeController = ({ intl, theme }) => {
    const [file, setFile] = useState(null);
    const [validationResult, setValidationResult] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const [isValidating, setIsValidating] = useState(false);
    const [isDoneValidating, setIsDoneValidating] = useState(false);

    const onVerify = (values) => {
        const { identifierType, identifier } = values;
        openbadgeVerify(file, identifierType, identifier)
            .then(result => {
                setValidationResult(result);
            })
            .catch((error) => {
                setValidationError(error);
            })
            .finally(() => {
                setIsValidating(false);
                setIsDoneValidating(true);
            });
        setIsValidating(true);
    };

    const handleFileUpload = info => {
        return new Promise((resolve, reject) => {
            setFile(info.file);
            resolve();
        });
    };

    const handleRemoveFile = () => {
        setFile(null);
    };

    const onRestart = () => {
        setFile(null);
        setIsValidating(false);
        setIsDoneValidating(false);
        setValidationResult(null);
        setValidationError(null);
    };

    let content;
    if (!isDoneValidating && !isValidating) {
        content = (
            <OpenbadgeForm
                fileList={file ? [file] : []}
                onVerify={onVerify}
                onFileUpload={e => handleFileUpload(e)}
                onClose={handleRemoveFile}
            />
        );
    } else if (isValidating && !isDoneValidating) {
        content = <OpenbadgeValidating fileList={file ? [file] : []} />
    } else if (isDoneValidating && validationError) {
        content = (
            <Alert
                style={{ marginBottom: '1em' }}
                type={'error'}
                message={intl.formatMessage({ id: 'vp.connectionError' })}
                showIcon
            />
        );
    } else if (isDoneValidating && validationResult) {
        content = <OpenbadgeValidationResult intl={intl} validationResult={validationResult} theme={theme} />;
    }

    return <>
        {content}
        {
            isDoneValidating &&
            <div style={{ marginBottom: '1em', textAlign: 'center' }}>
                <Button type="default" onClick={onRestart}>
                    {intl.formatMessage({ id: "vp.restart" })}
                </Button>
            </div>
        }
    </>;
};

export default OpenbadgeController;
