import React from "react";
import styled from "styled-components";
import { DeleteOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export const ButtonWrapper = styled.div`
    margin: 25px;
    text-align: center;
    button {
        margin: 0 5px;
    }
`;

export const StyledFileIcon = styled.span`
    .anticon {
        font-size: 50px;
        color: #1b2845;
    }
`;

export const FileList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`;

export const FileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    position: relative;

    &:hover {
        .anticon {
            opacity: 1;
        }
    }

    .file-name {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 10px;
        font-size: 14px;
        width: 120px;
    }
    .tool-tip-wrapper {
        display: flex;
    }
`;

export const FileName = ({ name, onRemove }) => (
    <div className="file-name">
        <Tooltip title={name} placement="left">
            <div className="tool-tip-wrapper">
                <span>{`${name.slice(0, 12)}...`}</span>
                {!!onRemove && (
                    <div onClick={onRemove}>
                        <DeleteOutlined />
                    </div>
                )}
            </div>
        </Tooltip>
    </div>
);
