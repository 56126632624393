import { repton_dxb } from "../repton_dxb/theme";

const footerLinks = [
    {
        text: "shared.support",
        href: "mailto:passport@reptonalbarsha.org"
    },
    {
        text: "footer.privacy",
        href: "https://www.reptonalbarsha.org/privacy-policy/"
    },
    {
        text: "shared.educhain",
        href: "https://educhain.io"
    }
];

const repton_barsha = {
    ...repton_dxb,
    name: "Repton Al Barsha",
    value: "repton_barsha",
    footerLinks: footerLinks,
};

export { repton_barsha };
