import React, { useEffect, useState } from "react";
import { verify, exportFiles } from "../../api";
import { WhileValidating } from "./WhileValidating";
import { FinishedValidating } from "./FinishedValidating";

import BeforeValidatingCredentials from "./BeforeValidatingCredentials";
import { FormattedMessage } from "react-intl";

const TIMEOUT = 1500;

const VerifyController = ({ locale }) => {
    const [fileList, setFileList] = useState([]);
    const [isValidating, setIsValidating] = useState(false);
    const [validateStep, setValidateStep] = useState(0);
    const [isDoneValidating, setIsDoneValidating] = useState(false);
    const [data, setData] = useState(null);
    const steps = [
        { id: 0, text: <FormattedMessage id="vp.processing" /> },
        { id: 1, text: <FormattedMessage id="vp.validating" /> },
        { id: 2, text: <FormattedMessage id="vp.verifying" /> }
    ];

    useEffect(() => {
        if (validateStep >= 0 && validateStep < 2 && isValidating) {
            waitAndIncrement(TIMEOUT);
        }
        if (data && validateStep >= 2) {
            setTimeout(() => {
                setIsValidating(false);
                setIsDoneValidating(true);
            }, TIMEOUT);
        }
    }, [validateStep, isValidating, data]);

    const waitAndIncrement = timeout => {
        setTimeout(() => {
            setValidateStep(validateStep + 1);
        }, timeout);
    };

    const onVerify = () => {
        verify(fileList, locale).then(data => setData(data));
        setIsValidating(true);
    };

    const handleFileUpload = info => {
        return new Promise((resolve, reject) => {
            setFileList(info.fileList);
            resolve();
        });
    };

    const handleRemoveFile = name => {
        const newState = fileList.filter(obj => obj.name !== name);
        setFileList(newState);
    };

    const handleExport = () => {
        exportFiles(data.data.hash, locale).then(res => {
            window.location.href = res.request.responseURL;
        });
    };

    let content;
    if (!isDoneValidating && !isValidating) {
        content = (
            <BeforeValidatingCredentials
                fileList={fileList}
                onVerify={onVerify}
                onFileUpload={e => handleFileUpload(e)}
                onClose={handleRemoveFile}
            />
        );
    } else if (isValidating && !isDoneValidating) {
        content = (
            <WhileValidating
                fileList={fileList}
                steps={steps}
                validateStep={validateStep}
            />
        );
    } else if (isDoneValidating) {
        content = (
            <FinishedValidating
                data={data}
                fileList={fileList}
                onExport={handleExport}
            />
        );
    }
    return <>{content}</>;
};

export default VerifyController;
