import React from "react";
import { Steps } from "antd";
import styled from "styled-components";

const { Step } = Steps;

const StepWrapper = styled.div`
    padding-top: 20px;
    .ant-steps-item {
        -ms-flex: 1 0 auto;
    }
    .ant-steps-item-finish
        .ant-steps-item-icon
        > .ant-steps-icon
        .ant-steps-icon-dot {
        background: ${({ theme: { colors } }) => colors.secondary};
    }

    .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        background: ${({ theme: { colors } }) => colors.secondary};
    }

    .ant-steps-item-process
        .ant-steps-item-icon
        > .ant-steps-icon
        .ant-steps-icon-dot {
        background: ${({ theme: { colors } }) => colors.secondary};
    }
`;

export const ValidationSteps = ({ validateStep, steps }) => {
    return (
        <StepWrapper>
            <Steps progressDot current={validateStep}>
                {steps.map(step => (
                    <Step title={step.text} key={step.id} />
                ))}
            </Steps>
        </StepWrapper>
    );
};
