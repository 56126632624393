import React from "react";
import styled, { withTheme } from "styled-components";
import { FormattedMessage } from "react-intl";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { LanguageSelectorMenu } from "./LanguageSelector";
import { getImagePath } from "@educhain/utils/theme/getImagePath";

const StyledFooter = styled.footer`
    @media (max-width: 992px) {
        display: flex;
        flex-flow: column-reverse;
    }

    font-weight: 300;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
    border-top: 2px solid #f5f5f5;
    z-index: 1;

    ul {
        @media (max-width: 992px) {
            margin: 5px 0;
            text-align: center;
         }

        padding: 0;
        font-weight: 300;
        position: relative;

        li {
            list-style: none;
            display: inline-block;
          }

        }

   a {
        color: rgba(0, 0, 0, 0.65);
        display: inline-block;
        vertical-align: middle;
        line-height: 20px;
        transition: all 0.3s ease-in-out;
        text-decoration: none;

        &:hover {
            color: rgba(0, 0, 0, 0.4);
        }
    }
    span {
        color: rgba(0, 0, 0, 0.65);
        display: inline-block;
        vertical-align: middle;
        line-height: 20px;
        transition: all 0.3s ease-in-out;
        text-decoration: none;
    }

   li:not(:last-child)::after {
     ${({ theme: { rtl } }) =>
         rtl ? "content: ''" : "content: '|'; margin: 0 10px;"};
        }

   li:not(:first-child)::before {
     ${({ theme: { rtl } }) =>
         rtl ? "content: '|'; margin: 0 10px" : "content: '';"};
        }
    .footer-contents {
    @media (max-width: 992px) {
        flex-direction: column-reverse;
        align-items: center
        }

        display: flex;
        justify-content: space-between;
        margin: auto;
    }

    .footer-bottom {
          @media (max-width: 992px) {
            margin-top: -10px;
            justify-content: center;
            flex-direction: column-reverse;
            align-items: center;
          }

        display: flex;
        justify-content: space-between;

        .power-wrapper {
            padding-top: 3px;
         }

        .link-wrapper {
            margin-top: 3px
         }
    }

    .footer-img {
        height: 24px;
        margin-bottom: 3px;
    }
}
`;

const BaseFooter = ({
    theme: { footerLinks = [] },
    className = "",
    redirectName,
    redirectPath,
    isRTLEnabled = false,
    theme,
    match: {
        params: { locale }
    }
}) => {
    return (
        <StyledFooter className={className}>
            <div className="footer-contents">
                <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <span>
                        {!!theme.footerCC ? (
                            <FormattedMessage id={theme.footerCC} />
                        ) : (
                            <FormattedMessage id="footer.copyTM" values={{ year: new Date().getFullYear() }} />
                        )}
                    </span>
                </div>
                <div>
                    <ul>
                        {isRTLEnabled && (
                            <li key="languageSelect">
                                <LanguageSelectorMenu />
                            </li>
                        )}
                        <li key="verifyCredential">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href={redirectPath}>{redirectName}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="link-wrapper">
                    <ul>
                        {footerLinks.map(({ text, href }, i) => {
                            return (
                                <li key={i}>
                                    <a target="_blank" href={href.replace('{locale}', locale)}>
                                        <FormattedMessage id={text} />
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="power-wrapper">
                    <FormattedMessage id="shared.power" />{" "}
                    <a target="_blank" href="https://educhain.io">
                        <img
                            className="footer-img"
                            src={getImagePath(theme.assets.educhain)}
                            alt=""
                        />
                    </a>
                </div>
            </div>
        </StyledFooter>
    );
};

export const Footer = withRouter(withTheme(BaseFooter));
