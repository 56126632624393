import { createGlobalStyle, css, withTheme } from 'styled-components';
import { INTL_LTR, INTL_RTL } from '@educhain/utils';

const GlobalStyle = createGlobalStyle`
  body {
    direction: ${({ theme: { rtl } }) => (rtl ? INTL_RTL : INTL_LTR)};
  }

  ${({ theme: { fonts } }) => (
    fonts.map(({ name, url, format, weight, style }) => (
      css`
        @font-face {
          font-family: ${name};
          src: url("${url}")${format ? ' format("opentype")' : ''};
          font-weight: ${weight ? weight : 'normal'};
          font-style: ${style ? style : 'normal'};
        }`
    ))
  )}

  .ant-btn-primary {
    border-color: ${({ theme: { colors } }) => colors.btnPrimary};
    background-color: ${({ theme: { colors } }) => colors.btnPrimary};
  }

  .ant-btn-primary:hover {
    border-color: ${({ theme: { colors } }) => colors.btnPrimary};
    background-color: ${({ theme: { colors } }) => colors.btnPrimary};
    filter: brightness(1.1);
  }

  h1 {
    color: ${({ theme: { colors } }) => colors.h1 || colors.primaryLight};
  }

  h2 {
    color: ${({ theme: { colors } }) => colors.h2 || colors.primaryLight};
  }

  h3 {
    color: ${({ theme: { colors } }) => colors.h3 || colors.primaryLight};
  }

  h4 {
    color: ${({ theme: { colors } }) => colors.h4 || colors.primaryLight};
  }

  h5 {
    color: ${({ theme: { colors } }) => colors.h5 || colors.primaryLight};
  }

  .ant-tabs-ink-bar {
    background: ${({ theme: { colors } }) => colors.btnPrimary};
  }
`;

export default withTheme(GlobalStyle);
