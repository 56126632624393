import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const FAQContent = ({ faq: { html, style }, faqComponent }) => {
    if (html) {
        return <Wrapper dangerouslySetInnerHTML={{ __html: html }} style={style}/>;
    }

    if (faqComponent) {
        return <Wrapper style={style}>{faqComponent}</Wrapper>
    }

    return null;
};

export default FAQContent;
