import VerifyController from "./components/VerifyController";
import OpenbadgeController from "./components/OpenbadgeController";

import React, { useState, useEffect } from "react";
import { Col, Row, Tabs as AntTabs } from "antd";
import styled, { withTheme } from "styled-components";
import { withSize } from "@educhain/ui/src/hoc/withSize";
import { injectIntl } from "react-intl";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { AR_LOCALE } from "@educhain/utils";
import VerifyLayout from "../layout/VerifyLayout";
import FAQContent from '@educhain/ui/src/faq/FAQContent';

const FIXEDTABHEIGHT = 400;

const AntTabPane = styled(AntTabs.TabPane)``;

const StyledTabs = styled(AntTabs)``;

const TabWrapper = styled.div`
    display: flex;
    align-items: center;
    color: grey;

    .ant-tabs {
        width: 100%;
    }

    .ant-tabs-nav {
        font-size: 14px;
        @media (min-width: 768px) {
            font-size: 18px;
        }
    }

    .ant-tabs .ant-tabs-left-bar .ant-tabs-nav {
        margin-top: 100px;
    }
    .ant-tabs .ant-tabs-left-content {
        padding-left: 75px;
    }

    .ant-tabs .ant-tabs-left-bar {
        border-right: 1px solid rgba(132, 137, 158, 0.5);
    }

    @media (min-width: 768px) {
        .ant-tabs .ant-tabs-left-bar,
        .ant-tabs .ant-tabs-right-bar {
            height: ${({ fixedHeight }) => fixedHeight}px;
        }
    }

    .ant-tabs-nav .ant-tabs-tab {
        color: ${({ theme: { colors } }) => colors.tab ? colors.tab : '#bbb9bd'};
        background: ${({ theme: { colors } }) => colors.tabBackground ? colors.tabBackground : 'transparent'};
        padding: 8.2px 24px;
        font-weight: 500;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: ${({ theme: { colors } }) => colors.tabActive ? colors.tabActive : '#5baaf5'};
        font-weight: 500;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: ${({ theme: { colors } }) => colors.tabActive ? colors.tabActive : '#318ae9'};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${({ theme: { colors } }) => colors.tabActive ? colors.tabActive : '#318ae9'};
    }

    .ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
    .ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
        padding: 30px 45px;
    }

    .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar {
        width: ${({ theme: { assets } }) => assets.tabBarWidth ? assets.tabBarWidth : '2px'};
    }
`;

const TabContent = styled.div`
    width: 100%;
    min-height: ${({ fixedHeight }) => fixedHeight}px;
    max-width: 1200px;
    display: flex;
    overflow-y: auto;
`;

const Wrapper = styled.div`
    width: 100%;
    min-height: 100%;
    background: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ant-row {
        width: 100%;
        &:first-child {
            margin: 25px 0;
        }

        &:last-child {
            margin-bottom: 50px;
        }
    }

    .divider {
        width: 120px;
        border: 2px solid ${({ theme: { colors } }) => colors.primaryLighter};
        margin-bottom: 15px;
        float: ${({ theme: { verify: { head } } }) => head.align};
    }

    .clearfix::after {
        content: " ";
        clear: both;
    }

    .started {
        text-align: ${({ theme: { verify: { started } } }) => started.align};
        color: ${({ theme: { colors } }) => colors.text};
    }

    h1 {
        color: ${({ theme: { colors } }) => colors.h1 || colors.primaryLight};
        text-align: ${({ theme: { verify: { head } } }) => head.align};
    }

    h3 {
        @media (min-width: 768px) {
            font-size: 20px;
        }

        font-size: 16px;
        color: ${({ theme: { colors } }) => colors.h3 || colors.primaryLight};
    }
`;

const BaseVerificationView = ({
    intl,
    lg,
    match: {
        params: { locale }
    },
    theme
}) => {
    const [activeKey, setActiveKey] = useState("1");

    const onTabSelect = key => {
        setActiveKey(key);
    };

    const grid = {
        xl: { span: 16 },
        lg: { span: 16 },
        md: { span: 16 },
        sm: { span: 22 },
        xs: { span: 22 },
        flex: 1
    };
    let tabLayout;
    if (lg) {
        tabLayout = "top";
    } else if (locale.toLowerCase() === AR_LOCALE) {
        tabLayout = "right";
    } else {
        tabLayout = "left";
    }

    return (
        <VerifyLayout>
            <Wrapper>
                <Row justify={"center"}>
                    <Col {...grid}>
                        <h1>
                            {theme.verify.title ? theme.verify.title : intl.formatMessage({ id: "myc.verifyCredential" })}
                        </h1>
                        <div className="divider" />
                        <div className="clearfix" />
                        <p className="started">{theme.verify.started.text ? theme.verify.started.text : intl.formatMessage({ id: "vp.getStarted" })}</p>
                    </Col>
                </Row>
                <Row justify={"center"}>
                    <Col {...grid}>
                        <TabWrapper fixedHeight={FIXEDTABHEIGHT}>
                            <StyledTabs
                                activeKey={activeKey}
                                onChange={key => onTabSelect(key)}
                                className="Ant"
                                tabPosition={tabLayout}
                            >
                                <AntTabPane
                                    tab={intl.formatMessage({
                                        id: "vp.uploadCredentials"
                                    })}
                                    key="1"
                                >
                                    <TabContent fixedHeight={FIXEDTABHEIGHT}>
                                        <VerifyController locale={locale} />
                                    </TabContent>
                                </AntTabPane>

                                {process.env.REACT_APP_VP_HIDE_BADGES !== 'true' && (
                                    <AntTabPane
                                        tab={intl.formatMessage({
                                            id: "vp.openBadges"
                                        })}
                                        key="2"
                                    >
                                        <OpenbadgeController intl={intl} locale={locale} theme={theme} />
                                    </AntTabPane>
                                )}
                                {!!theme.verify.faq && (
                                    <AntTabPane
                                        tab={intl.formatMessage({
                                            id: "vp.FAQ"
                                        })}
                                        key="3"
                                    >
                                        <FAQContent intl={intl} locale={locale} faq={theme.verify.faq} />
                                    </AntTabPane>
                                )}
                            </StyledTabs>
                        </TabWrapper>
                    </Col>
                </Row>
            </Wrapper>
        </VerifyLayout>
    );
};

export const VerificationView = withRouter(
    injectIntl(withSize(withTheme(BaseVerificationView)))
);
