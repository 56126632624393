import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { getTheme } from "@educhain/themes/src/";
import Main from "./Main";

const theme = getTheme();

export default class App extends Component {
    render() {
        return (
            <>
                <Helmet>
                    {" "}
                    <title> {theme.brand} | {theme.verify.pageSubtitle ? theme.verify.pageSubtitle : 'Verification Portal'} </title>{" "}
                </Helmet>
                <Router>
                    <Switch>
                        <Route exact path="/" component={Main} />
                        <Route path={`/:locale`} component={Main} />
                    </Switch>
                </Router>
            </>
        );
    }
}
