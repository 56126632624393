export const getCurrentUsername = () => {
    if (typeof localStorage === 'undefined') {
        return null;
    }

    return getItem('user');
};

export const setCurrentUsername = (username) => {
    if (!username) {
        return;
    }

    setItem('user', username);
}

export const removeCurrentUsername = () => removeItem('user');

export const getCurrentUserToken = (key) => {
    const currentUsername = getCurrentUsername();

    return getToken(key, currentUsername);
}

export const getToken = (key, username) => {
    if (typeof localStorage === 'undefined') {
        return null;
    }

    const tokens = JSON.parse(getItem(key) || '{}');

    return tokens[username];
};

export const setToken = (key, username, token) => {
    if (!key || !username || !token) {
        return;
    }

    const tokens = JSON.parse(getItem(key) || '{}');

    tokens[username] = token;
    setItem(key, JSON.stringify(tokens));
}

export const removeToken = (key) => removeItem(key);

const setItem = (key, value) => {
    return localStorage.setItem(key, value);
}

const getItem = (key) => {
    return localStorage.getItem(key);
}

const removeItem = (key) => {
    return localStorage.removeItem(key);
}
