import { defaultTheme } from '../default/theme';

const footerLinks = [
    {
        text: "uaeuFooter.privacy",
        href: "https://www.uaeu.ac.ae/en/website/privacy_policy.shtml"
    },
    {
        text: "uaeuFooter.terms",
        href: "https://www.uaeu.ac.ae/en/website/terms_of_use.shtml"
    },
    {
        text: "uaeuFooter.copy",
        href: "https://www.uaeu.ac.ae/en/website/copyright.shtml"
    }
];

const uaeu = {
    ...defaultTheme,
    name: "Red theme (UAEU)",
    value: "uaeu",
    brand: "UAEU",
    website: "https://uaeu.ac.ae/en/",
    colors: {
        ...defaultTheme.colors,
        border: "#e5e5e5",
        primary: "#E91921",
        primaryLight: "#ff5b5b",
        primaryDark: "#cf3238",
        error: "#f5222d"
    },
    footerLinks: footerLinks,
    verifyURL: "https://verify.uaeu.ac.ae",
    phone: "+971 3 7134299 | +971 3 7136776",
    email: "Registration_department@uaeu.ac.ae",
    assets: {
        ...defaultTheme.assets,
        uaeu: "uaeu.png",
        uaeuSignature: "uaeu_signature.png",
        headingFont: "Dubai, sans-serif",
    },
    menuTitle: "uaeuMenu",
    phoneName: "uaeuPhone",
    headerMessage: "uaeuHeaderMessage",
    footerCC: "uaeuFooter.prePrivacy",
    ldap: true,
    fonts: [
        {
            name: 'Dubai',
            url: '/assets/DubaiW23-Regular.woff',
            format: 'woff',
            weight: 'normal',
            style: 'normal',
        },
    ],
};

export { uaeu };
