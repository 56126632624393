import { repton_dxb } from "../repton_dxb/theme";

const footerLinks = [
    {
        text: "shared.support",
        href: "mailto:passport@reptonabudhabi.org"
    },
    {
        text: "footer.privacy",
        href: "https://www.reptonabudhabi.org/privacy-policy/ "
    },
    {
        text: "shared.educhain",
        href: "https://educhain.io"
    }
];

const repton_ad = {
    ...repton_dxb,
    name: "Repton Abu Dhabi",
    value: "repton_ad",
    footerLinks: footerLinks,
};

export { repton_ad };
