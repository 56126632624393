import React from "react";
import styled from "styled-components";
import { FileList, FileName, FileWrapper, StyledFileIcon } from "./styled";
import { FileTextOutlined, LoadingOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .loading {
        font-size: 30px;
    }
`;

export const OpenbadgeValidating = ({ fileList }) => {
    return (
        <Wrapper>
            <FileList>
                {fileList.map(file => (
                    <FileWrapper key={file.name}>
                        <StyledFileIcon>
                            <FileTextOutlined />
                        </StyledFileIcon>
                        <FileName name={file.name} />
                    </FileWrapper>
                ))}
            </FileList>
            <div className="loading">
                <LoadingOutlined />
            </div>
        </Wrapper>
    );
};
